import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../../components/PageWrapper";
import styled from "styled-components";
import SEO from "../../../components/seo";
import BackButton from "../../../components/Core/BackButton";
import PageTitle from "../../../components/Core/PageTitle";

const Content = styled.div`

    $ a:hover {
        color: silver;
    }

    & h2 {
        margin: 1.5rem 0;
    }
`

const BlogArticle = ({data}) => {
    const headerConfig = {
        paddingBottom: 10,
    };

    return (
        <>  
            <PageWrapper headerConfig={headerConfig}>
                <SEO title="Anthos Fund & Asset Managment" />
                <Content>
                    <BackButton />
                    <Container className=""> 
                        <Row className="">
                            <Col className="col-lg-2"></Col>
                            <Col className="col-lg-6">
                                <PageTitle
                                    title="Anthos Fund & Asset Management"
                                    align="left"
                                />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="col-lg-2 text-right">
                                <div className="mb-4 border-top pt-7">
                                    {/* COFRA Group */}
                                </div>
                                <div>
                                    {/* Last updated<br />
                                    20 May, 2022 */}
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <StaticImage
                                    src="img_afam_bridge.jpg"
                                    alt="Anthos Fund & Asset Management: car crossing bridge over beautiful turqois water"
                                    placeholder="blurred"
                                    layout="fixed"
                                    width={480}
                                    style={{maxWidth: '100%'}}
                                    className="mb-10"
                                />
                                <p>
                                    Established almost 100 years ago to manage capital for the Brenninkmeijer family, Anthos Fund & Asset Management has provided comprehensive values-based asset management in numerous asset classes to the family, their philanthropies and pension funds of group entities. Clients benefit from tailored expert management across equities, fixed income, real estate, private equity, alternative investments and on an overall portfolio level. 
                                </p>
                                <p>
                                    Based on a tradition of purpose, excellence, collaboration, client focus and passion, Anthos Fund & Asset Management's approach has a long-term track record of outperforming benchmarks while contributing to the common good. 
                                </p>
                                <p>
                                    Anthos Fund & Asset Management is a signatory to the UN Principles for Responsible Investment, has a long track record in the field of impact investing and has responsible investing (RI) embedded across its investment process. 
                                </p>
                                <p>
                                    Early 2021, Anthos Fund & Asset Management announced its ambition to become net zero carbon by 2040.
                                </p>
                                <p>
                                    In May 2022, Anthos Fund & Asset Management announced they are opening up their investment platform to values-driven asset owners. The new strategy is focused on accelerating the transition to a more sustainable, equitable and just society by offering Anthos' asset management capabilities to like-minded asset owners.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Content>
            </PageWrapper>
        </>
    )
}

export default BlogArticle;